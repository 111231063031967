import * as React from "react"
import { ContactStyles } from "./ContactStyles"

const Contact = () => {
  const [success, setSuccess] = React.useState(false)
  
  React.useEffect(() => {
    const params = new URLSearchParams(
      window.location.href.slice(window.location.href.indexOf("?"))
    )

    if (params.get('success') === 'true') {
      setSuccess(true);
    } else {
      setSuccess(false);
    }
  }, [success])

  return (
    <ContactStyles>
      {success && (
        <span className="message" style={{ color: "green" }}>
          Formularz zostal wysłany.
        </span>
      )}
      <form
        name="contact"
        style={{ marginTop: "1em" }}
        action="https://app.99inbound.com/api/e/_Uqrbrds"
        method="POST"
      >
        <input required placeholder="Imię" type="text" name="name" />
        <input required placeholder="Email" type="email" name="email" />
        <input
          placeholder="Data uroczystości (dd/mm/rrrr)"
          name="date"
          type="text"
        />
        <input placeholder="Miejsce uroczystości" type="text" name="place" />
        <textarea
          required
          placeholder="Wiadomość"
          name="message"
          rows="5"
        ></textarea>
        <button className="btn" type="submit">
          Wyślij
        </button>
      </form>
    </ContactStyles>
  )
}

export default Contact
